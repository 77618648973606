import React from "react";
import './WebPageStylings/HLBOverviewPage.css';
import MegaFooter from '../MegaFooter';

const HLBOverviewPage = () => {
  return (
    <div className="hlb-overview-page">
      {/* About Section */}
      <div className="container about-section">
        <h1>About HLB Technology Inc.</h1>
        <p>
          HLB Technology Inc. is a Memphis-based startup redefining what it
          means to create AI that serves humanity. Founded by Mr. Bowers and Mr.
          Henley, our mission is simple yet profound: to ensure artificial
          intelligence operates ethically and aligns with human values. Through
          innovative products like MultiChatXpert and ETHICIAN, we are at the
          forefront of developing AI solutions that are not just tools but
          trusted partners in shaping a better future.
        </p>
      </div>

      {/* Goals Section */}
      <div className="container goals-section">
        <h1>HLB Technology's Goals: Shaping the Future of Ethical AI</h1>
        <p>
          At HLB Technology Inc., our mission is clear: to pioneer ethical AI
          solutions that align with humanity's values and empower individuals,
          organizations, and industries to leverage artificial intelligence
          responsibly. We envision a future where AI serves as a trusted partner
          in innovation, problem-solving, and decision-making—always prioritizing
          ethical considerations and human dignity.
        </p>
        <h2>Our Core Goals:</h2>
        <ul>
          <li>
            <strong>Lead in Ethical AI Development:</strong> By advancing research into AI alignment,
            Quantum Consciousness, and the interplay of Coherence and Decoherence,
            we aim to set new standards for AI systems capable of nuanced ethical
            reasoning and meaningful interactions.
          </li>
          <li>
            <strong>Empower Through Accessibility:</strong> With products like MultiChatXpert and
            the ETHICIAN API, we seek to democratize access to powerful and ethical
            AI tools, making them available to users at every level—from individuals
            and developers to enterprise clients.
          </li>
          <li>
            <strong>Drive Industry Transformation:</strong> By tailoring ETHICIAN for enterprise
            applications, we strive to create AI solutions that enhance industries
            such as healthcare, education, HR, and finance. Our goal is to help
            organizations address complex challenges with AI systems that are as
            principled as they are effective.
          </li>
          <li>
            <strong>Innovate Responsibly:</strong> Our commitment to ethical innovation extends to
            exploring emerging technologies like quantum computing and VR/AR
            integration. We aim to push the boundaries of what AI can achieve while
            ensuring that every advancement reflects our dedication to transparency,
            trust, and societal well-being.
          </li>
          <li>
            <strong>Promote Global Collaboration:</strong> Through initiatives like the ETHICIAN API
            and our open ethical AI toolkit, we aspire to foster a global community
            of developers, researchers, and organizations working together to build
            a future powered by responsible AI.
          </li>
        </ul>
        <p>
          At HLB Tech, we believe that technology is most impactful when guided by
          purpose. Our goals reflect our unwavering dedication to creating AI systems
          that not only advance innovation but also embody the values that unite
          humanity. Together, we are shaping a future where AI serves as a force for
          good, driving progress while safeguarding the principles that define us.
        </p>
      </div>

      {/* Leadership and Governance Section */}
      <div className="container leadership-section">
        <h1>Our Leadership and Governance</h1>
        <p>
          At HLB Technology Inc., governance is driven by a shared commitment to
          ethical innovation and accountability. Mr. Bowers, our founder and
          owner, provides visionary leadership, ensuring the company’s strategic
          direction aligns with its core mission of humanity-first AI. Mr.
          Henley, our lead developer, oversees the technical roadmap with
          expertise in AI alignment, ensuring all projects adhere to the
          highest ethical and technological standards. Together, they form a
          governance structure that balances strategic foresight with technical
          rigor, fostering a culture of transparency, innovation, and
          responsibility. Though compact in size, HLB Technology operates with
          a robust governance model that underscores its commitment to making a
          meaningful impact through ethical AI solutions.
        </p>
      </div>

      {/* Website Announcement Section */}
      <div className="container website-announcement-section">
        <h1>HLB Tech's New Website: A Work in Progress</h1>
        <p>
          HLB Technology Inc. is excited to announce the launch of our new
          website, a dynamic platform designed to showcase our innovative
          projects and research. As a work in progress, the site reflects our
          commitment to transparency and collaboration as we continue to refine
          its features.
        </p>
        <p>
          Coinciding with the Alpha release of the Lite version of
          MultiChatXpert, our new website provides a space for users to explore
          the capabilities of our AI systems, stay updated on our latest news,
          and connect with our vision for ethical AI.
        </p>
        <p>
          Stay tuned for more updates as we expand the site with additional
          content, features, and resources to support our growing community. We
          invite you to join us on this journey toward a more transparent and
          ethically-driven future.
        </p>
      </div>
      <MegaFooter />
    </div>
  );
};

export default HLBOverviewPage;
